/**Institucionales de Servicios **/
Vue.component('serviceecp', {
    template: `<div>
                <aside>
                  <div>
                    <ul>
                      <li><router-link  to="/company/sso" >ONE STOP SHOPPING</router-link></li>
                      <li><router-link  to="/company/catalogo">CATÁLOGOS</router-link></li>
                      <li><router-link class="vselected"  to="/company/ecp">EC PERSONALIZADOS</router-link></li>
                      <li><router-link to="/company/pedidos">PEDIDOS FÁCILES</router-link></li>
                      <li><router-link to="/company/entregas">ENTREGAS EN TODO EL PAÍS</router-link></li>
                    </ul>
                  </div>
                </aside>
                <section>
                   <article>
                    <img src="../static/ecommerce/assets/marken/services/ecp/bannerecp.jpg">
                     <div>
                      <div style="width: 500px; float: left; padding-top: 10px">
                      <p> 
                      Te ofrecemos el mejor soporte y servicio <br> posible desarrollado a medida de tus <br> necesidades y la de tu empresa.
                      </p>
                      <p>
                      Ofrecemos una estructura altamente <br> responsiva que se adapta a tus requerimientos individuales.
                      </p>
                      <p>
                      Desde tu ejecutivo de cuentas hasta nuestro <br> soporte de IT y nuestro equipo de entregas, dispondras de todo un equipo comprometido <br> tanto a entregar el mejor servicio posible como <br> para ayudarte a alcanzar tus objetivos de <br> presupuesto.
                      </p>
                      </div>
                      <div style="float: left;">
                        <img src="../static/ecommerce/assets/marken/services/ecp/ec.jpg">
                      </div>
                     </div>
                   </article>
                </section>
                </div>`
});
Vue.component('servicepedidos', {
    template: `<div>
                <aside>
                    <div>
                    <ul>
                      <li><router-link  to="/company/sso" >ONE STOP SHOPPING</router-link></li>
                      <li><router-link  to="/company/catalogo">CATALOGOS</router-link></li>
                      <li><router-link  to="/company/ecp">EC PERSONALIZADOS</router-link></li>
                      <li><router-link  class="vselected" to="/company/pedidos">PEDIDOS FACILES</router-link></li>
                      <li><router-link to="/company/entregas">ENTREGAS EN TODO EL PAIS</router-link></li>
                    </ul>
                    </div>
                    </aside>
                <section>
                    <article>
                     <div>
                      <img src="../static/ecommerce/assets/marken/services/pedidos/pedidos.jpg">
                      <p style="font-weight: bold">
                      En marken te ofrecemos opciones simples, fáciles y flexibles para que puedas procesar <br> tus pedidos.
                      </p>
                      <p>
                      Por cualquiera de nuestros medios podrás procesar tus pedidos de manera ágil y segura.
                      </p>
                      <img src="../static/ecommerce/assets/marken/services/pedidos/icopedidos.jpg" class="center">
                      <h2>
                        NUESTRA CODIFICACIÓN DE PRODUCTOS ES SENCILLA Y COMPARTIDA POR <br> TODOS NUESTROS CATÁLOGOS Y MEDIOS DE COMPRA.
                      </h2>
                     </div>
                    </article>
                </section>
            </div>`

});
Vue.component('servicesso', {
    template: ` <div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/sso" class="vselected" >ONE STOP SHOPPING</router-link></li>
          <li><router-link to="/company/catalogo">CATALOGOS</router-link></li>
          <li><router-link to="/company/ecp">EC PERSONALIZADOS</router-link></li>
          <li><router-link to="/company/pedidos">PEDIDOS FACILES</router-link></li>
          <li><router-link to="/company/entregas">ENTREGAS EN TODO EL PAIS</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/services/sso/im_oss.jpg">
          <h2>
            SIMPLIFICAMOS TU VIDA
          </h2>
          <p> Te ofrecemos el más completo entorno de productos para tu oficina, junto con la mas amplia <br> oferta de servicios y soluciones. </p>
         </div>
       </article>
    </section>
    </div>`
});
Vue.component('servicecatalogo', {
    template: `<div>
                <div>
                    <aside>
                      <div>
                        <ul>
                          <li><router-link  to="/company/sso" >ONE STOP SHOPPING</router-link></li>
                          <li><router-link class="vselected" to="/company/catalogo">CATALOGOS</router-link></li>
                          <li><router-link to="/company/ecp">EC PERSONALIZADOS</router-link></li>
                          <li><router-link to="/company/pedidos">PEDIDOS FACILES</router-link></li>
                          <li><router-link to="/company/entregas">ENTREGAS EN TODO EL PAIS</router-link></li>
                        </ul>
                      </div>
                    </aside>
                    <section>
                       <article>
                         <div>
                             <img src="../static/ecommerce/assets/marken/services/catalogo/bannercatalogos.jpg">
                              <p>
                                No sabes cuál es mejor producto para tu necesidad?
                              </p>
                              <p>
                                No te preocupes, en Marken tenemos una larga experiencia en seleccionar las mejores marcas <br>
                                y productos disponibles tanto a nivel local como a nivel global.
                              </p>
                              <p>
                                Y además te los ofrecemos en catálogos que se han convertido en referencia en nuestra industria.
                              </p>
                              <img src="../static/ecommerce/assets/marken/services/catalogo/catalogos1.jpg" class="center">
                              <p>
                                Anualmente entregamos sin cargo el único y más completo catálogo de productos de oficina <br> para todo el pais.
                              </p>
                              <p>
                                Más de 8000 productos comodamente agrupados, con fotos y descripciones completas que <br> te ayudarán a elegir el producto más adecuado.
                              </p>
                              <hr>
                              <img src="../static/ecommerce/assets/marken/services/catalogo/catalogos2.jpg" class="center">
                              <p>
                              Esta verisión digital del catálogo impreso puede visualizarse on line, o bien descargarse a <br> cualquier dispositivo.
                              </p>
                              <p>
                                Asombrate con las herramientas interactivas: vínculos, buscadores, índices, señaladores y <br> notas virtuales que harán tus busquedas más ágiles y divertidas.
                              </p>
                              <h2>
                                PODES VERLO ONLINE, DESCARGARLO O IMPRIMIRLO.
                              </h2>
                              <hr>
                              <img src="../static/ecommerce/assets/marken/services/catalogo/catalogos3.jpg" class="center">
                              <p>
                                Toda nuestra oferta de productos actualizada y con precios vigentes.
                              </p>
                              <h2>
                                PODES NAVEGARLO POR CATEGORIAS, REDUCIENDO BUSQUEDAS APLICANDO Y <br> COMBINANDO AUTOFILTROS.
                              </h2>
                         </div>
                       </article>
                    </section>
                </div>
          </div>`
});
Vue.component('serviceentregas', {
    template: ` <div>
                    <aside>
                      <div>
                        <ul>
                         <li><router-link  to="/company/sso" >ONE STOP SHOPPING</router-link></li>
                          <li><router-link to="/company/catalogo">CATALOGOS</router-link></li>
                          <li><router-link to="/company/ecp">EC PERSONALIZADOS</router-link></li>
                          <li><router-link to="/company/pedidos">PEDIDOS FACILES</router-link></li>
                          <li><router-link class="vselected" to="/company/entregas">ENTREGAS EN TODO EL PAIS</router-link></li>
                        </ul>
                      </div>
                    </aside>
                    <section>
                       <article>
                         <div>
                          <img src="../static/ecommerce/assets/marken/services/entregas/entregas.jpg">
                          <h2>
                          APLICAMOS LA FILOSOFÍA DE OFRECER EL RESULTADO ESPERADO EN EL PRIMER INTENTO, <br> SIEMPRE.
                          </h2>
                          <p>
                          No importa donde esté, disponemos de soluciones para que reciba lo que pidió, cuando lo pidió <br> y donde lo pidió.
                          </p>
                          <p>
                          Servicio de entregas sin cargo y en 24/48 horas para caba y gba, cobertura con alcance nacional <br> y flexibilidad de entregas para realizar entregas puerta a puerta o según requerimientos <br> especificos del cliente.
                          </p>
                         </div>
                       </article>
                    </section>
                </div>`

});
/**Institucionales de About  **/
Vue.component('aboutbeneficios', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv">VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios" class="vselected" >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/beneficios/bannerbeneficios.jpg">
          <br>
          <h2> COMO? EN 8 SIMPLES PASOS </h2>
          <p><font style="font-weight: bold">
          Simplificando tu proceso de compras<br>
          </font>
          Desde productos de librería, café, papel higiénico y cascos hasta resmas de papel y cajas para</br>
          embalar. Un solo proveedor, un solo pedido, una sola factura.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tus opciones y presupuesto </br>
          </font>
          Te ofrecemos más de 7.000 productos en nuestro catálogo y con stock permanente, y más de</br>
          40.000 productos por pedido especial. Todas las marcas que vos preferís con los valores más</br>
          competitivos del mercado.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tus entregas </br>
          </font>
          Entregamos en todo el país, con el costo de envío ya incluido o te entregamos en el transporte</br>
          que vos elijas.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu proceso de compras</br>
          </font>          
          Nuestro sitio de e-commerce habilitado las 24 horas, los 7 días de la semana.</br>
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu gestión administrativa</br>
          </font>
          Facturación electrónica? Consolidada? Por centro de costos? Nos adaptamos a cualquier</br>
          necesidad para reducir los gastos administrativos asociados al manejo de proveedores.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu contacto con nosotros <br>
          </font>
          Un solo contacto. Todas las soluciones. Te asignamos un ejecutivo de cuentas comercial con el <br>
          que podrás centralizar todas tus necesidades, desde consultas, cotizaciones, pedidos y reclamos <br>
          hasta asesoramiento sobre nuestro productos y herramientas.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu búsqueda de productos <br>
          </font>
          Tenes dudas sobre el producto más adecuado? No hay problema. Te ofrecemos catálogos <br>
          impresos, digitales y on-line para ayudarte a elegir el producto correcto.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu integración global <br>
          </font>
          Queres replicar el mismo modelo de abastecimiento que utiliza tu empresa en el resto del<br>
          mundo? Nosotros te ayudamos. Siendo parte de la estructura comercial de Lyreco, líder<br>
          mundial en el mercado BtoB para el abastecimiento de productos de oficina, disponemos<br>
          del know how para acompañarte en la implementación de las últimas herramientas y flujo<br>
          de trabajo en procesos de compra.         
        </p>
        </div>
       </article>
    </section>
    </div>`

});
Vue.component('aboutvmv', {
    template: ` <div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv" class="vselected" >VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios"  >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/vmv/bannervisionyvalores.jpg">
          <h2>VISIÓN</h2>
          <p> Ser una empresa argentina referente por brindar un servicio integral, sustentable y de excelencia. </p>
          <h2>MISIÓN</h2>
          <p>
          Ofrecer servicios y soluciones para el abastecimiento de productos e insumos de oficina mediante <br> una gestión confiable y transparente, excediendo los estándares de la industria
          </p>
          <h2>
          VALORES
          </h2>
          <p>
          <font style="font-weight: bold">
          1. Relaciones a largo plazo: </font> que perduren en el tiempo afianzando las relaciones y honrando los <br> 
          contratos.<br> 
          <font style="font-weight: bold">
          2. Eficiencia y puntualidad: </font>  en las entregas pactadas, con stocks que nos respaldan para cumplir <br> 
          aún en condiciones de mercado inestables<br> 
          <font style="font-weight: bold">
          3. Responsabilidad: </font> ofreciendo un ambiente de trabajo seguro para todos los empleados y una <br> 
          logística sustentable.<br> 
          <font style="font-weight: bold">
          4. Confiabilidad: </font> mediante la gestión transparente.<br> 
          <font style="font-weight: bold">
          5. Desarrollo sostenible: </font> nuestro negocio enfatiza el compromiso con la eficiencia ambiental, los <br> 
          derechos humanos y el bienestar social.
          </p>
          <img src="../static/ecommerce/assets/marken/about/vmv/visionyvalores.jpg" class="center">
         </div>
       </article>
    </section>
    </div>`

});
Vue.component('aboutetica', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv">VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica" class="vselected">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios"  >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/codigoetica/bannercodigoetica.jpg">
          <br>
          <h2> EMPLEADOS </h2>
          <p> Nuestros empleados esperan un trato justo, por eso es primordial el compromiso respecto a la <br>
          ley aplicable, al mismo tiempo que el cumplimiento con responsabilidad de las tareas asignadas.
          </p>
          <p>Nuestro Código de Ética para empleados es una muestra del compromiso y respeto mutuo.</p>
          <br>
          <h2> PROVEEDORES </h2>
          <p>
          Reconocemos y asumimos asimismo un rol activo en nuestra cadena de valor, tomando posición <br>
          activa en la búsqueda continua del respeto y el cumplimiento de las normas legales.
          </p>
          <p>
          Los proveedores son un eslabón fundamental en la cadena de valor, por eso buscamos y <br>
          estimulamos relaciones de largo plazo basadas en el respeto por los compromisos.
          </p>
          <p>
          Nuestro Código de Conducta para Proveedores es una manera de comunicarnos y fortalecer la <br>
          confianza para construir relaciones sostenibles.
          </p>
         </div>
       </article>
    </section>
</div>`

});
/**Institucionales de rse  **/
Vue.component('rseambiental', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv">VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios" class="vselected" >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/beneficios/bannerbeneficios.jpg">
          <br>
          <h2> COMO? EN 8 SIMPLES PASOS </h2>
          <p><font style="font-weight: bold">
          Simplificando tu proceso de compras<br>
          </font>
          Desde productos de librería, café, papel higiénico y cascos hasta resmas de papel y cajas para</br>
          embalar. Un solo proveedor, un solo pedido, una sola factura.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tus opciones y presupuesto </br>
          </font>
          Te ofrecemos más de 7.000 productos en nuestro catálogo y con stock permanente, y más de</br>
          40.000 productos por pedido especial. Todas las marcas que vos preferís con los valores más</br>
          competitivos del mercado.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tus entregas </br>
          </font>
          Entregamos en todo el país, con el costo de envío ya incluido o te entregamos en el transporte</br>
          que vos elijas.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu proceso de compras</br>
          </font>          
          Nuestro sitio de e-commerce habilitado las 24 horas, los 7 días de la semana.</br>
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu gestión administrativa</br>
          </font>
          Facturación electrónica? Consolidada? Por centro de costos? Nos adaptamos a cualquier</br>
          necesidad para reducir los gastos administrativos asociados al manejo de proveedores.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu contacto con nosotros <br>
          </font>
          Un solo contacto. Todas las soluciones. Te asignamos un ejecutivo de cuentas comercial con el <br>
          que podrás centralizar todas tus necesidades, desde consultas, cotizaciones, pedidos y reclamos <br>
          hasta asesoramiento sobre nuestro productos y herramientas.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu búsqueda de productos <br>
          </font>
          Tenes dudas sobre el producto más adecuado? No hay problema. Te ofrecemos catálogos <br>
          impresos, digitales y on-line para ayudarte a elegir el producto correcto.
          </p>
          <p><font style="font-weight: bold">
          Simplificando tu integración global <br>
          </font>
          Queres replicar el mismo modelo de abastecimiento que utiliza tu empresa en el resto del<br>
          mundo? Nosotros te ayudamos. Siendo parte de la estructura comercial de Lyreco, líder<br>
          mundial en el mercado BtoB para el abastecimiento de productos de oficina, disponemos<br>
          del know how para acompañarte en la implementación de las últimas herramientas y flujo<br>
          de trabajo en procesos de compra.         
        </p>
        </div>
       </article>
    </section>
    </div>`

});
Vue.component('rseeconomicos', {
    template: ` <div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv" class="vselected" >VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios"  >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/vmv/bannervisionyvalores.jpg">
          <h2>VISIÓN</h2>
          <p> Ser una empresa argentina referente por brindar un servicio integral, sustentable y de excelencia. </p>
          <h2>MISIÓN</h2>
          <p>
          Ofrecer servicios y soluciones para el abastecimiento de productos e insumos de oficina mediante <br> una gestión confiable y transparente, excediendo los estándares de la industria
          </p>
          <h2>
          VALORES
          </h2>
          <p>
          <font style="font-weight: bold">
          1. Relaciones a largo plazo: </font> que perduren en el tiempo afianzando las relaciones y honrando los <br> 
          contratos.<br> 
          <font style="font-weight: bold">
          2. Eficiencia y puntualidad: </font>  en las entregas pactadas, con stocks que nos respaldan para cumplir <br> 
          aún en condiciones de mercado inestables<br> 
          <font style="font-weight: bold">
          3. Responsabilidad: </font> ofreciendo un ambiente de trabajo seguro para todos los empleados y una <br> 
          logística sustentable.<br> 
          <font style="font-weight: bold">
          4. Confiabilidad: </font> mediante la gestión transparente.<br> 
          <font style="font-weight: bold">
          5. Desarrollo sostenible: </font> nuestro negocio enfatiza el compromiso con la eficiencia ambiental, los <br> 
          derechos humanos y el bienestar social.
          </p>
          <img src="../static/ecommerce/assets/marken/about/vmv/visionyvalores.jpg" class="center">
         </div>
       </article>
    </section>
    </div>`

});
Vue.component('rseprincipios', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv">VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica" class="vselected">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios"  >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/codigoetica/bannercodigoetica.jpg">
          <br>
          <h2> EMPLEADOS </h2>
          <p> Nuestros empleados esperan un trato justo, por eso es primordial el compromiso respecto a la <br>
          ley aplicable, al mismo tiempo que el cumplimiento con responsabilidad de las tareas asignadas.
          </p>
          <p>
          Nuestro Código de Ética para para empleados es una muestra del compromiso y respeto mutuo.
          </p>
          <br>
          <h2> PROVEEDORES </h2>
          <p>
          Reconocemos y asumimos asimismo un rol activo en nuestra cadena de valor, tomando posición <br>
          activa en la búsqueda continua del respeto y el cumplimiento de las normas legales.
          </p>
          <p>
          Los proveedores son un eslabón fundamental en la cadena de valor, por eso buscamos y <br>
          estimulamos relaciones de largo plazo basadas en el respeto por los compromisos.
          </p>
          <p>
          Nuestro Código de Conducta para Proveedores es una manera de comunicarnos y fortalecer la <br>
          confianza para construir relaciones sostenibles.
          </p>
         </div>
       </article>
    </section>
</div>`

});
Vue.component('rsesocial', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/vmv">VISIÓN Y VALORES</router-link></li>
          <li><router-link to="/company/etica" class="vselected">CÓDIGO DE ÉTICA</router-link></li>
          <li><router-link to="/company/beneficios"  >BENEFICIOS</router-link></li>
          <li><router-link to="/company/bancaria">INFORMACIÓN BANCARIA</router-link></li>
          <li><router-link to="/company/legajo">LEGAJO IMPOSITIVO</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>
          <img src="../static/ecommerce/assets/marken/about/codigoetica/bannercodigoetica.jpg">
          <br>
          <h2> EMPLEADOS </h2>
          <p> Nuestros empleados esperan un trato justo, por eso es primordial el compromiso respecto a la <br>
          ley aplicable, al mismo tiempo que el cumplimiento con responsabilidad de las tareas asignadas.
          </p>
          <p>
          Nuestro Código de Ética para para empleados es una muestra del compromiso y respeto mutuo.
          </p>
          <br>
          <h2> PROVEEDORES </h2>
          <p>
          Reconocemos y asumimos asimismo un rol activo en nuestra cadena de valor, tomando posición <br>
          activa en la búsqueda continua del respeto y el cumplimiento de las normas legales.
          </p>
          <p>
          Los proveedores son un eslabón fundamental en la cadena de valor, por eso buscamos y <br>
          estimulamos relaciones de largo plazo basadas en el respeto por los compromisos.
          </p>
          <p>
          Nuestro Código de Conducta para Proveedores es una manera de comunicarnos y fortalecer la <br>
          confianza para construir relaciones sostenibles.
          </p>
         </div>
       </article>
    </section>
</div>`

});

/**Institucionales de rse  **/
Vue.component('contactclientes', {
    template: `<div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/clientes" class="vselected">CLIENTES</router-link></li>
          <li><router-link to="/company/proveedores">PROVEEDORES</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <div>  
          <img src="../static/ecommerce/assets/marken/contacto/clientes/clientes.jpg">
          <br>
          <h2> HOLA! GRACIAS POR CONTACTARTE CON NOSOTROS </h2>
          <p>Por favor completa el siguiente formulario, y en breve te estaremos dando una respuesta</br>sobre tu inquietud.</p>
          <form class="formulario" action="../ecommerce/api/contactus" method="post">
              <input type="hidden" :value="$route.path" name="redirectUrl">
              <input type="hidden" value="CLIENTE" name="contactType">
              <p style="font-weight: bold">
                  Motivo del contacto</br>
                  <select class="browser-default" name="motivo_del_contacto">
                    <option>SOLICITUD DE CATALOGO</option>
                    <option>INFORMACION DE PRODUCTO</option>
                    <option>SOLICITUD DE PRECIO</option>
                    <option>FACTURACION</option>
                    <option>ENTREGA</option>
                    <option>PEDIDO</option>
                    <option>DEVOLUCION</option>
                    <option>ACTUALIZAR MIS DATOS</option>
                    <option>OTROS</option>
                  </select>
              </p>
              <p class="clearfix" style="font-weight: bold">
                  Razón social</br>
                  <input name="razon_social">
              </p>
              <p style="font-weight: bold">
                  CUIT</br>          
                  <input name="cuit">
              </p>
              <p style="font-weight: bold">
                  Apellido</br>
                  <input name="apellido">
              </p>
              <p style="font-weight: bold">
                   Nombre <br>
                   <input name="nombre">
              </p>
              <p style="font-weight: bold">
                    Cargo<br>
                    <input name="cargo">
              </p>
              <p style="font-weight: bold"> 
                E-Mail <br>
                <input name="email" type="email">
              </p>
              <p style="font-weight: bold">
                    Teléfono <br>         
                    <input name="telefono">
              </p>
              <p style="font-weight: bold">
                   Mensaje <br>
                   <textarea name="mensaje"></textarea>
                   <br> <br>
                   <input type="submit" value="Enviar" />
                   <br>
              </p>
          </form>
        <hr>
        <div style="padding-left: 10px;padding-top: 10px">
          <div style="float: left;">
          <img src="../static/ecommerce/assets/marken/contacto/clientes/Telefono.jpg" style="margin-left: 80px; margin-top: 10px">
          </div>
          <div style="float: left;">
          <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
          Teléfono <br>
          </font> 
          (011) 4515-8900 <br>
          de Lunes a Viernes <br>
          de 9.00 a 18.00 hs.
          </p>
          </div> 
          <div style="float: left;">
          <img src="../static/ecommerce/assets/marken/contacto/clientes/Mail.jpg" style="margin-left: 100px; margin-top: 10px">
          </div>
          <div style="float: left;">
          <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
          E-mail <br>
          </font>
          ventas@marken.com.ar
          </p>
          </div> 
          <div style="float: left;">
          <img src="../static/ecommerce/assets/marken/contacto/clientes/Correo.jpg" style="margin-left: 100px; margin-top: 10px">
          </div>
          <div style="float: left;">
          <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
          Correo <br>
          </font>
          Av. L.N.Alem 1002 P.9<br>
          Piso 9 <br>
          C1058AAP - Bs.As. - Arg.<br>
          </p>
          </div> 
        </div>
        </div>
       </article>
    </section>
    </div>`

});

Vue.component('contactproveedores', {
    template: ` <div>
    <aside>
      <div>
        <ul>
          <li><router-link to="/company/clientes">CLIENTES</router-link></li>
          <li><router-link to="/company/proveedores" class="vselected">PROVEEDORES</router-link></li>
        </ul>
      </div>
    </aside>
    <section>
       <article>
         <form>
          <img src="../static/ecommerce/assets/marken/contacto/proveedores/proveedores.jpg">
          <br>
          <h2> BIENVENIDO A MARKEN! </h2>
          <p>Si deseas formar parte de nuestro equipo de proveedors, por favor completa el siguiente <br>
          formulario
          </p>
          <form class="formulario" action="../ecommerce/api/contactus" method="post">
              <input type="hidden" :value="$route.path" name="redirectUrl">
              <input type="hidden" value="PROVEEDOR" name="contactType">
              <p style="font-weight: bold">
                    Razón social</br>
                    <input name="razon_social">
              </p>
              <p style="font-weight: bold">
                    CUIT</br>    
                    <input name="cuit">
              </p>
              <p style="font-weight: bold">
                    Apellido</br>
                    <input name="apellido">
              </p>
              <p style="font-weight: bold">
                Nombre <br>
                <input name="nombre">
              </p>
              <p style="font-weight: bold">
                Sitio web<br>
                <input name="sitioweb">
              </p>
              <p style="font-weight: bold">
                    E-Mail <br>
                    <input name="email" type="email">
              </p>
              <p  style="font-weight: bold"> 
                Teléfono <br>
                <input name="telefono">
              </p>
              <p style="font-weight: bold">
                  Mensaje (favor indicar productos que comercializa)<br>
                  <textarea name="mensaje"></textarea>
              </p>
              <br>
              <button type="submit">Enviar</button>
          </form>
         </div>
         <hr>
        <div style="padding-left: 10px;padding-top: 10px">
          <div style="float: left;">
              <img src="../static/ecommerce/assets/marken/contacto/clientes/Telefono.jpg" style="margin-left: 80px; margin-top: 10px">
          </div>
          <div style="float: left;">
              <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
              Teléfono <br>
              </font> 
              (011) 4515-8900 <br>
              de Lunes a Viernes <br>
              de 9.00 a 18.00 hs.
              </p>
          </div> 
          <div style="float: left;">
              <img src="../static/ecommerce/assets/marken/contacto/clientes/Mail.jpg" style="margin-left: 100px; margin-top: 10px">
          </div>
          <div style="float: left;">
              <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
              E-mail <br>
              </font>
              ventas@marken.com.ar
              </p>
          </div> 
          <div style="float: left;">
              <img src="../static/ecommerce/assets/marken/contacto/clientes/Correo.jpg" style="margin-left: 100px; margin-top: 10px">
          </div>
          <div style="float: left;">
              <p style="font-size: 13px; padding-left: 10px"><font style="font-weight: bold">
              Correo <br>
              </font>
              Av. L.N.Alem 1002 P.9<br>
              Piso 9 <br>
              C1058AAP - Bs.As. - Arg.<br>
              </p>
          </div> 
        </div>
        </div>
       </article>
    </section>
    </div>`

});